import React from 'react';
import { connect } from 'react-redux';
import { translate, Trans } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  PanelHeader,
  Spinner,
  UploadImage,
  GoogleMapLibrary
} from '../../components';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import Select from 'react-select';
import get from 'lodash/get';
import { photo, save, logo } from '../../helpers/actions/users';
import {
  businessTypes,
  specialities,
  skills
} from '../../helpers/nomenclators';
import avatar from '../../assets/img/default-avatar.png';
import { UserData } from '../../helpers/api';

class User extends React.Component {
  state = {
    loading: true,
    profile: {}
  };

  componentWillMount() {
    this.setState({ loading: true });

    UserData()
      .then(({ user }) => {
        this.setState({ profile: user, loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  changeAvatar(e) {
    e.preventDefault();
    this.setState({ loading: true });

    let { profile } = this.state,
      { dispatch } = this.props;

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      dispatch(photo(profile._id, file))
        .then(({ photoUrl }) => {
          profile.photoURL = reader.result;
          this.setState({ profile, loading: false });
        })
        .catch(() => this.setState({ loading: false }));
    };
    reader.readAsDataURL(file);
  }

  onChange(name, nameValid, value, valid) {
    let { profile } = this.state;

    if (name === 'work' || name === 'mobile' || name === 'landline') {
      this.setState({
        profile: { ...profile, phone: { ...profile.phone, [name]: value } },
        [nameValid]: valid ? 'has-success' : 'has-danger'
      });
    } else {
      if (
        name === 'commercial_name' ||
        name === 'commercial_status' ||
        name === 'manager' ||
        name === 'siret' ||
        name === 'siren' ||
        name === 'skills' ||
        name === 'specialities' ||
        name === 'description' ||
        name === 'delivery_address'
      ) {
        this.setState(prevState => {
          return {
            ...prevState,
            profile: {
              ...prevState.profile,
              pro: { ...prevState.profile.pro, [name]: value }
            },
            [nameValid]: valid ? 'has-success' : 'has-danger'
          };
        });
      } else {
        this.setState({
          profile: { ...profile, [name]: value },
          [nameValid]: valid ? 'has-success' : 'has-danger'
        });
      }
    }
  }

  validateProfile() {
    let { profile } = this.state;
    let name = this.name;
    let work = this.work;
    let mobile = this.mobile;
    let landline = this.landline;
    let description = this.description;
    let delivery_address = this.delivery_address;
    let commercialName = this.commercialName;
    let siret = this.siret;
    let siren = this.siren;
    let manager = this.manager;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      addressValid: 'has-success',
      [work.attributes.getNamedItem('namevalid').value]: work.validity.valid
        ? 'has-success'
        : 'has-danger',
      [mobile.attributes.getNamedItem('namevalid').value]: mobile.validity.valid
        ? 'has-success'
        : 'has-danger',
      [landline.attributes.getNamedItem('namevalid').value]: landline.validity
        .valid
        ? 'has-success'
        : 'has-danger',
      skillsValid: profile.pro.skills ? 'has-success' : 'has-success',
      specialitiesValid: profile.pro.specialities
        ? 'has-success'
        : 'has-success',
      [description.attributes.getNamedItem('namevalid').value]: description
        .validity.valid
        ? 'has-success'
        : 'has-danger',
      [delivery_address.attributes.getNamedItem('namevalid')
        .value]: delivery_address.validity.valid ? 'has-success' : 'has-danger',
      [commercialName.attributes.getNamedItem('namevalid')
        .value]: commercialName.validity.valid ? 'has-success' : 'has-danger',
      commercialStatusValid: 'has-success',
      [siret.attributes.getNamedItem('namevalid').value]: siret.validity.valid
        ? 'has-success'
        : 'has-danger',
      [siren.attributes.getNamedItem('namevalid').value]: siren.validity.valid
        ? 'has-success'
        : 'has-danger',
      [manager.attributes.getNamedItem('namevalid').value]: manager.validity
        .valid
        ? 'has-success'
        : 'has-danger'
    });

    return (
      name.validity.valid &&
      mobile.validity.valid &&
      work.validity.valid &&
      landline.validity.valid &&
      description.validity.valid &&
      delivery_address.validity.valid &&
      profile.pro.skills &&
      profile.pro.specialities &&
      commercialName.validity.valid &&
      siret.validity.valid &&
      siren.validity.valid &&
      manager.validity.valid
    );
  }

  changeLogo(e) {
    e.preventDefault();
    this.setState({ loading: true });

    let { profile } = this.state,
      { dispatch } = this.props;

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      dispatch(logo(profile._id, file))
        .then(({ photoUrl }) => {
          profile.pro.logo = reader.result;
          this.setState({ profile, loading: false });
        })
        .catch(() => this.setState({ loading: false }));
    };
    reader.readAsDataURL(file);
  }

  // handleClickLogo() {
  //   let input = document.createElement('input');

  //   input.type = 'file';
  //   input.onchange = e => {
  //     e.preventDefault();
  //     this.handleLogoChange(e.target.files[0]);
  //   };
  //   input.click();
  // }

  // handleLogoChange(file) {
  //   let { profile } = this.state;
  //   let reader = new FileReader();

  //   reader.onloadend = () => {
  //     profile.pro.logo = reader.result;
  //     this.setState({ profile, fileLogo: file });
  //   };
  //   reader.readAsDataURL(file);
  // }

  async updateProfile() {
    if (this.validateProfile()) {
      this.setState({ loading: true });

      let { profile } = this.state,
        { dispatch } = this.props;

      if (profile.address && profile.address !== '') {
        await geocodeByAddress(profile.address)
          .then(results => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            profile.lat = lat;
            profile.lng = lng;
          })
          .catch(() => {
            profile.lat = null;
            profile.lng = null;
          });
      } else {
        profile.lat = null;
        profile.lng = null;
      }

      dispatch(save(profile))
        .then(() => {
          this.setState({ loading: false });
        })
        .catch(() => this.setState({ loading: false }));
    }
  }

  render() {
    let { loading, profile } = this.state,
      { t } = this.props;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Row>
            <Col md={12} xs={12}>
              <Card>
                <CardHeader>
                  <h5 className="title">
                    <Trans>Edit Profile</Trans>
                  </h5>
                </CardHeader>
                <CardBody>
                  {profile ? (
                    <Form className="form-horizontal">
                      <Row>
                        <Col xs={12}>
                          <h6>
                            <Trans>Personal Information</Trans>
                          </h6>
                          <hr />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={3}>
                          <FormGroup className={'text-center pt-2'}>
                            <UploadImage
                              avatar={true}
                              image={
                                profile.photoURL ? profile.photoURL : avatar
                              }
                              textButton={t('Change Avatar')}
                              inputName={'avatarImage'}
                              onChange={event => this.changeAvatar(event)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={9}>
                          <Row>
                            <Col xs={12} md={4}>
                              <FormGroup>
                                <Label>
                                  <Trans>Email</Trans>
                                </Label>
                                <Input
                                  type="email"
                                  disabled={true}
                                  value={get(profile, 'email', '')}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={4}>
                              <FormGroup
                                className={'has-label ' + this.state.nameValid}
                              >
                                <Label>
                                  <Trans>Display Name</Trans>
                                </Label>
                                <Input
                                  type="text"
                                  innerRef={node => (this.name = node)}
                                  namevalid="nameValid"
                                  value={get(profile, 'displayName', '')}
                                  required="required"
                                  onChange={event =>
                                    this.onChange(
                                      'displayName',
                                      'nameValid',
                                      event.target.value,
                                      event.target.validity.valid
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={4}>
                              <FormGroup>
                                <Label>
                                  <Trans>Company</Trans>
                                </Label>
                                <Input
                                  type="text"
                                  disabled={true}
                                  value={'Placeshaker'}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={12}>
                              <FormGroup
                                className={
                                  'has-label ' + this.state.addressValid
                                }
                              >
                                <Label>
                                  <Trans>Address</Trans>
                                </Label>
                                <GoogleMapLibrary>
                                  <PlacesAutocomplete
                                    value={
                                      profile.address ? profile.address : ''
                                    }
                                    onChange={address =>
                                      this.onChange(
                                        'address',
                                        'addressValid',
                                        address,
                                        true
                                      )
                                    }
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps
                                    }) => (
                                      <div>
                                        <input
                                          {...getInputProps({
                                            className: 'form-control'
                                          })}
                                        />
                                        <ul
                                          className={
                                            'dropdown-menu inner ' +
                                            (suggestions.length === 0
                                              ? ''
                                              : 'show')
                                          }
                                        >
                                          {suggestions.map(suggestion => (
                                            <li
                                              {...getSuggestionItemProps(
                                                suggestion
                                              )}
                                            >
                                              <a
                                                href="/#"
                                                className="dropdown-item"
                                              >
                                                <span className="text">
                                                  {suggestion.description}
                                                </span>
                                              </a>
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                                </GoogleMapLibrary>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={12}>
                              <Label>
                                <Trans>Phones</Trans>
                              </Label>
                              <hr className={'mt-0'} />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} md={4}>
                              <FormGroup
                                className={'has-label ' + this.state.workValid}
                              >
                                <Label>
                                  <Trans>Work</Trans>
                                </Label>
                                <Input
                                  type="text"
                                  innerRef={node => (this.work = node)}
                                  namevalid="workValid"
                                  value={get(profile.phone, 'work', '')}
                                  onChange={event =>
                                    this.onChange(
                                      'work',
                                      'workValid',
                                      event.target.value,
                                      event.target.validity.valid
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={4}>
                              <FormGroup
                                className={
                                  'has-label ' + this.state.mobileValid
                                }
                              >
                                <Label>
                                  <Trans>Mobile</Trans>
                                </Label>
                                <Input
                                  type="text"
                                  innerRef={node => (this.mobile = node)}
                                  namevalid="mobileValid"
                                  value={get(profile.phone, 'mobile', '')}
                                  onChange={event =>
                                    this.onChange(
                                      'mobile',
                                      'mobileValid',
                                      event.target.value,
                                      event.target.validity.valid
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={4}>
                              <FormGroup
                                className={
                                  'has-label ' + this.state.landlineValid
                                }
                              >
                                <Label>
                                  <Trans>Landline</Trans>
                                </Label>
                                <Input
                                  type="text"
                                  innerRef={node => (this.landline = node)}
                                  namevalid="landlineValid"
                                  value={get(profile.phone, 'landline', '')}
                                  onChange={event =>
                                    this.onChange(
                                      'landline',
                                      'landlineValid',
                                      event.target.value,
                                      event.target.validity.valid
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={6}>
                          <FormGroup
                            className={
                              'has-label form-validation-40 ' +
                              this.state.specialitiesValid
                            }
                          >
                            <Label>
                              <Trans>Specialities</Trans>
                            </Label>
                            <Select
                              className="primary"
                              multi={true}
                              closeOnSelect={false}
                              options={specialities.map(p => ({
                                label: t(p),
                                value: p
                              }))}
                              value={get(profile, 'pro.specialities', '')}
                              onChange={event =>
                                this.onChange(
                                  'specialities',
                                  'specialitiesValid',
                                  event.map(e => e.value),
                                  !!event.length
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <FormGroup
                            className={
                              'has-label form-validation-40 ' +
                              this.state.skillsValid
                            }
                          >
                            <Label>
                              <Trans>Skills</Trans>
                            </Label>
                            <Select
                              className="primary"
                              multi={true}
                              closeOnSelect={false}
                              options={skills.map(p => ({
                                label: t(p),
                                value: p
                              }))}
                              value={get(profile, 'pro.skills', '')}
                              onChange={event =>
                                this.onChange(
                                  'skills',
                                  'skillsValid',
                                  event.map(e => e.value),
                                  !!event.length
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup
                            className={
                              'has-label form-validation-40 ' +
                              this.state.delivery_addressValid
                            }
                          >
                            <Label>
                              <Trans>Delivery Address</Trans>
                            </Label>
                            <GoogleMapLibrary>
                              <PlacesAutocomplete
                                value={get(profile, 'pro.delivery_address', '')}
                                onChange={delivery_address =>
                                  this.onChange(
                                    'delivery_address',
                                    'delivery_addressValid',
                                    delivery_address,
                                    true
                                  )
                                }
                              >
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps
                                }) => (
                                  <div>
                                    <input
                                      ref={node =>
                                        (this.delivery_address = node)
                                      }
                                      {...getInputProps({
                                        className: 'form-control',
                                        namevalid: 'delivery_addressValid'
                                      })}
                                    />
                                    <ul
                                      className={
                                        'dropdown-menu inner ' +
                                        (suggestions.length === 0 ? '' : 'show')
                                      }
                                    >
                                      {suggestions.map(suggestion => (
                                        <li
                                          {...getSuggestionItemProps(
                                            suggestion
                                          )}
                                        >
                                          <a
                                            href="/#"
                                            className="dropdown-item"
                                          >
                                            <span className="text">
                                              {suggestion.description}
                                            </span>
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </GoogleMapLibrary>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          <FormGroup
                            className={
                              'has-label form-validation-40 ' +
                              this.state.descriptionValid
                            }
                          >
                            <Label>
                              <Trans>Description</Trans>
                            </Label>
                            <Input
                              type="textarea"
                              innerRef={node => (this.description = node)}
                              namevalid="descriptionValid"
                              value={get(profile, 'pro.description', '')}
                              onChange={event =>
                                this.onChange(
                                  'description',
                                  'descriptionValid',
                                  event.target.value,
                                  event.target.validity.valid
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col xs={12}>
                          <h6>
                            <Trans>Company Information</Trans>
                          </h6>
                          <hr />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} md={3}>
                          <FormGroup className={'text-center pt-2'}>
                            <UploadImage
                              avatar={true}
                              image={
                                profile.pro && profile.pro.logo
                                  ? profile.pro.logo
                                  : avatar
                              }
                              textButton={t('Change')}
                              inputName={'logoImage'}
                              onChange={event => this.changeLogo(event)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={9}>
                          <Row>
                            <Col xs={12} md={6}>
                              <FormGroup
                                className={
                                  'has-label form-validation-40 ' +
                                  this.state.commercialNameValid
                                }
                              >
                                <Label>
                                  <Trans>Commercial Name</Trans>
                                </Label>
                                <Input
                                  type="text"
                                  innerRef={node =>
                                    (this.commercialName = node)
                                  }
                                  namevalid="commercialNameValid"
                                  value={get(
                                    profile,
                                    'pro.commercial_name',
                                    ''
                                  )}
                                  onChange={event =>
                                    this.onChange(
                                      'commercial_name',
                                      'commercialNameValid',
                                      event.target.value,
                                      event.target.validity.valid
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                              <FormGroup
                                className={
                                  'has-label form-validation-40 ' +
                                  this.state.commercialStatusValid
                                }
                              >
                                <Label>
                                  <Trans>Commercial Status</Trans>
                                </Label>
                                <Select
                                  className="primary"
                                  value={get(
                                    profile,
                                    'pro.commercial_status',
                                    ''
                                  )}
                                  options={businessTypes.map(pc => ({
                                    label: t(pc),
                                    value: pc
                                  }))}
                                  onChange={event => {
                                    this.onChange(
                                      'commercial_status',
                                      'commercialStatusValid',
                                      event ? event.value : '',
                                      true
                                    );
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                              <FormGroup
                                className={
                                  'has-label form-validation-40 ' +
                                  this.state.siretValid
                                }
                              >
                                <Label>
                                  <Trans>Siret</Trans>
                                </Label>
                                <Input
                                  type="text"
                                  innerRef={node => (this.siret = node)}
                                  namevalid="siretValid"
                                  value={get(profile, 'pro.siret', '')}
                                  onChange={event =>
                                    this.onChange(
                                      'siret',
                                      'siretValid',
                                      event.target.value,
                                      event.target.validity.valid
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                              <FormGroup
                                className={
                                  'has-label form-validation-40 ' +
                                  this.state.sirenValid
                                }
                              >
                                <Label>
                                  <Trans>Siren</Trans>
                                </Label>
                                <Input
                                  type="text"
                                  innerRef={node => (this.siren = node)}
                                  namevalid="sirenValid"
                                  value={get(profile, 'pro.siren', '')}
                                  onChange={event =>
                                    this.onChange(
                                      'siren',
                                      'sirenValid',
                                      event.target.value,
                                      event.target.validity.valid
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col xs={12} md={6}>
                              <FormGroup
                                className={
                                  'has-label form-validation-40 ' +
                                  this.state.managerValid
                                }
                              >
                                <Label>
                                  <Trans>Manager</Trans>
                                </Label>
                                <Input
                                  type="text"
                                  innerRef={node => (this.manager = node)}
                                  namevalid="managerValid"
                                  value={get(profile, 'pro.manager', '')}
                                  onChange={event =>
                                    this.onChange(
                                      'manager',
                                      'managerValid',
                                      event.target.value,
                                      event.target.validity.valid
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  ) : null}
                </CardBody>
                <CardFooter className={'text-right'}>
                  <Button color={'info'} onClick={() => this.updateProfile()}>
                    <Trans>Save</Trans>
                  </Button>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(User));
